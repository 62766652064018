import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Image from "../shared/image"

const About = styled.section`
  margin: 0 auto;
  width: 100%;
  padding: 0px 1.0875rem 1.45rem;
`
const Details = styled.details.attrs(props => ({
  className: "p-10 mb-10",
}))`
  border-radius: 10px;
  &:focus {
    outline: 0;
  }
  > summary {
    cursor: pointer;

    &:after {
      float: right;
      content: "+";
    }
  }
  &[open] summary:after {
    content: "-";
  }
  > summary::-webkit-details-marker {
    display: none;
  }
  > summary:focus {
    outline: 0;
  }
`
const AboutContent = ({ className }) => {
  const data = useStaticQuery(graphql`
    query Images {
      smartway: file(relativePath: { eq: "SmartWay.jpg" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <About className={className}>
      <div className="w-full mt-10">
        <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
          <summary className="font-semibold text-gray-800 text-xl lg:text-2xl mb-2">
            What is Archerhub?
          </summary>
          <p className="text-gray-700 text-lg pt-5">
            Archerhub is a high growth digital freight marketplace that connects
            shippers with truck drivers seamlessly via our technology that
            creates visibility and actionable insight to our customers and
            carriers.
          </p>
        </Details>
      </div>
      <div className="w-full">
        <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
          <summary className="font-semibold text-gray-800 text-xl lg:text-2xl mb-2">
            What is our goal?
          </summary>
          <p className="text-gray-700 text-lg pt-5">
            Our goal is to democratize the carrier procurement process while
            delivering full transparency and visibility to our users.
          </p>
        </Details>
      </div>
      <div className="w-full">
        <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
          <summary className="font-semibold text-gray-800 text-xl lg:text-2xl mb-2">
            What is our culture like?
          </summary>
          <p className="text-gray-700 text-lg pt-5">
            We strive to create a culture of collaboration with a mindset to
            getting the job done without any excuses.
          </p>
        </Details>
      </div>
      {/* <div className="w-full mb-20">
        <Card className="max-w-full bg-white rounded overflow-hidden shadow-lg">
          <div className="px-8 py-6">
            <div className="font-bold text-gray-800 text-xl mb-2">
              Why Archerhub?
            </div>
            <p className="text-gray-700 text-lg">
              At Archerhub we know you have hundreds of options whom to choose
              as your logistics provider. For every logistics solution we
              provide, we know there are competitors and they can do the same
              thing that we do. Therefore, every time we talk to our customers
              we keep this in mind in order to earn our customer’s trust and
              respect. The only way to do this is to listen carefully to our
              customers’ needs and problem points and then determine a way to
              solve those problems and do it better than our competitors at a
              reasonable price.
            </p>
          </div>
        </Card>
      </div> */}
      <div className="w-full">
        <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
          <summary className="font-semibold text-gray-800 text-xl lg:text-2xl mb-2">
            What is our mission?
          </summary>
          <p className="text-gray-700 text-lg pt-5">
            Our mission is to optimize processes to reduce waste and deliver
            efficiency by pushing everyone at Archerhub to keep our customer's
            clients success in mind.
          </p>
        </Details>
      </div>
      <div className="w-full mb-5">
        <div className="text-gray-800 font-bold text-3xl text-center">
          Our Certifications
        </div>
      </div>
      <div className="flex justify-center mb-10">
        <div className="flex-auto">
          <Image fixed={data.smartway.childImageSharp.fixed} title="Archerhub is proud to be certified by SmartWay Transport Partner" alt="Archerhub is proud to be certified by SmartWay Transport Partner" />
        </div>
      </div>
    </About>
  )
}

AboutContent.propTypes = {
  className: PropTypes.string,
}

AboutContent.defaultProps = {
  className: null,
}

export default AboutContent
