import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutContent from "../components/about-us/about-content"

const desc = "Learn about Archerhub's asset-based digital freight brokerage with an extensive freight marketplace that will get your shipments where they need to go."
const About = () => (
  <Layout>
    <SEO title="About Us" description={desc}/>
    <AboutContent className="container mx-auto flex flex-wrap justify-center" />
  </Layout>
)

export default About
